<template>
	<div class="content">

		<div v-html="datc"></div>
	</div>
</template>

<script>
	import {
		sundryAgreement,//入驻协议
		} from "../../utils/index.js"
	
	export default{
		data(){
			return{
				datc:''
			}
		},
		created() {
			this.sundryAgreement()
		},
		methods:{
		sundryAgreement(){
			sundryAgreement().then(res=>{
				console.log("入驻协议",res.data)
				this.datc=res.data
			})
		}	
		}
	}
</script>

<style lang="less">
	.content{
		width: 1400px;
		margin: 10px auto 0;
		background-color: #fff;
	}
</style>